<script lang="ts" setup>
import { ref, onMounted } from "vue";

const message = ref("Hello World!");
</script>

<template>
  <delay-hydration>
    <slot></slot>
  </delay-hydration>
</template>